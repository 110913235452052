// export const API_BASE_URL = "http://192.168.1.6:3100/api";
export const API_BASE_URL = "https://api.bestindiakart.com/api";
export const Key_Id = "rzp_live_9dYJhWA0Pt5Hcc";
export const key_secret = "oFq9KgNSIDuvk8cZKDis6nCZ";














