// import React, { useContext, useEffect, useState } from "react";
// import userContext from "../../../components/UseContext/UseContext";
// import axios from "axios";
// import { API_BASE_URL } from "../../../utils";
// import { IoCaretBack } from "react-icons/io5";
// import Lottie from "lottie-react";
// import { Link, useParams } from "react-router-dom";
// import Card from "react-bootstrap/Card";
// import WishlistButton from "../../Buttons/WishlistButton";
// import ProductOveriew from "../../ProductOveriew/ProductOveriew";
// import AddCartButton from "../../Buttons/AddCartButton";
// import ProductImages from "../../Products/ProductImages/ProductImages";
// import NoProductFound from "../../../Preloader/No product Found Animation.json";
// import Preloader from "../../../Preloader/Preloader-Animation.json";


// const ViewSubProduct = ({subcategoryName,categoryName}) => {

//     const [subCategories, setSubCategories] = useState([]);
//     const [subCategoriesName, setSubCategoriesName] = useState("");
//     const [loading, setLoading] = useState(true);
//     const subProducts = useContext(userContext);
//   console.log("sub category name list are showing --------->",subcategoryName)


//     useEffect(() => {
//       const fetchData = async () => {
//         try {
//           setLoading(true);
//           const response = await axios.get(
//             `${API_BASE_URL}/user/product/getprodutbysubcategory/${subcategoryName}`
//           );
//           console.log("sub product history ------->",response?.data?.products[0]?.category?._id)
//           setSubCategories(response?.data?.products);
//           subProducts.setSubProductDatas(response?.data?.products[0]?.category?._id)
//           console.log("sub product history ------->",response?.data?.products[0]?.category?._id)
  
  
//           setSubCategoriesName(response?.data?.subcategory?.name);
//           // console.log("response getprodutbysubcategory :", response?.data);
//         } catch (error) {
//           console.log("error", error);
//         } finally {
//           setLoading(false);
//         }
//       };
//       fetchData();
//     }, [subcategoryName]);
  
  
  
    
//     return (
//       <div className="row px-2r m-0 products-wrapper ">
//         <center className="products-heading-container my-4">
//           <h4 className="product-heading">{subCategoriesName}</h4>
//           <Link to={`/${categoryName}/${subcategoryName}`} className="view-all-products">
//             <IoCaretBack className="fs-5" />
//             View All
//           </Link>
//         </center>
  
//         {loading ? (
//           <Lottie
//             style={{ width: "100vw", height: "50vh" }}
//             animationData={Preloader}
//           />
//         ) : (
//           <>
//             {subCategories?.length !== 0 ? (
//               <div className="products m-0">
//                 {subCategories?.map((product, i) => (
//                   <div className="card" key={i}>
//                     <center>
//                       <Card.Title className="px-2 text-dark card-title">
//                         <Link
//                           className="text-dark text-decoration-none"
//                           to={`/part-number-page/${
//                             product?.descriptions &&
//                             product?.descriptions[0] &&
//                             product?.descriptions[0]?.value
//                           }`}
//                         >
//                           {product?.partNumber}
//                         </Link>
//                       </Card.Title>
//                     </center>
  
//                     <Link
//                       to={`/part-number-page/${
//                         product?.descriptions &&
//                         product?.descriptions[0] &&
//                         product?.descriptions[0]?.value
//                       }`}
//                     >
//                       <ProductImages productimages={product?.productimages} />
//                     </Link>
  
//                     <center className="cart-footer">
//                       <Link
//                         to={`/part-number-page/${
//                           product?.descriptions &&
//                           product?.descriptions[0] &&
//                           product?.descriptions[0]?.value
//                         }`}
//                         className="card-price text-decoration-none"
//                       >
//                         <h6 className="m-0 current-price">
//                           ₹ {product?.saleprice}
//                         </h6>
//                         <p className="m-0 old-price">₹ {product?.oldprice}</p>
//                       </Link>
  
//                       <AddCartButton
//                         type="product_button"
//                         className="card-add-cart-button"
//                         data={product}
//                       />
//                     </center>
  
//                     <div className="card-content text-danger">
//                       <WishlistButton type="icon" data={product} />
//                       <div className="add-to-card">
//                         <ProductOveriew product={product} />
//                       </div>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             ) : (
//               <Lottie
//                 style={{ width: "100vw", height: "50vh" }}
//                 animationData={NoProductFound}
//               />
//             )}
//           </>
//         )}
//       </div>
//     );
// }

// export default ViewSubProduct

import React, { useContext, useEffect, useState } from "react";
import userContext from "../../../components/UseContext/UseContext";
import axios from "axios";
import { API_BASE_URL } from "../../../utils";
import { IoCaretBack } from "react-icons/io5";
import Lottie from "lottie-react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import WishlistButton from "../../Buttons/WishlistButton";
import ProductOveriew from "../../ProductOveriew/ProductOveriew";
import AddCartButton from "../../Buttons/AddCartButton";
import ProductImages from "../../Products/ProductImages/ProductImages";
import NoProductFound from "../../../Preloader/No product Found Animation.json";
import Preloader from "../../../Preloader/Preloader-Animation.json";
import { Button } from "react-bootstrap";

const ViewSubProduct = ({ subcategoryName, categoryName }) => {
    const [subCategories, setSubCategories] = useState([]);
    const [subCategoriesName, setSubCategoriesName] = useState("");
    const [loading, setLoading] = useState(true);
    const subProducts = useContext(userContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(
                    `${API_BASE_URL}/user/product/getprodutbysubcategory/${subcategoryName}`
                );
                setSubCategories(response?.data?.products);
                subProducts.setSubProductDatas(response?.data?.products[0]?.category?._id);
                setSubCategoriesName(response?.data?.subcategory?.name);
            } catch (error) {
                console.log("error", error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [subcategoryName]);
    const format = (dateString) => {
        return dateString.replace(/%20/g, '-')
        .toLowecase();
      };
      

      const replaceSpacesWithHyphens = (urlString) => {

        if (typeof urlString === 'string') {
          // Replace all spaces with hyphens
          return urlString.replace(/ /g, '-').toLocaleLowerCase();
        }else return urlString;
    }
    return (
        <div className="row px-2r m-0 products-wrapper ">
            <div className="products-heading-container">
                <h4 className="product-heading  mt-2">{subCategoriesName}</h4>
                
                    <Button className="view-all-products text-end" style={{background:"#0443C0"}}>
                <Link to={(`/${categoryName}/${subcategoryName}`)} >
                    <IoCaretBack className="fs-5" />
                 View All
                </Link>
                  </Button>  
                   

            </div>

            {loading ? (
                <Lottie
                    style={{ width: "100vw", height: "50vh" }}
                    animationData={Preloader}
                />
            ) : (
                <>
                    {subCategories?.length !== 0 ? (
                        <div className="products m-0">
                            {subCategories.slice(0, 5).map((product, i) => (
                                <div className="card" key={i}>
                                    <center>
                                        <Card.Title className="px-2 text-dark card-title">
                                            <Link
                                                className="text-dark text-decoration-none"
                                                // to={`/part-number-page/${
                                                to={product?.descriptions[0]?.value !== undefined ?`/${replaceSpacesWithHyphens(categoryName)}/${replaceSpacesWithHyphens(subcategoryName)}/${
                                                    replaceSpacesWithHyphens(product?.descriptions &&
                                                    product?.descriptions[0] &&
                                                    product?.descriptions[0]?.value)
                                                }` : `/product/${product?.slug}`}
                                            >
                                                {product?.partNumber}
                                            </Link>
                                        </Card.Title>
                                    </center>

                                    <Link
                                        // to={`/part-number-page/${
                                       to={ product?.descriptions[0]?.value !== undefined ?`/${replaceSpacesWithHyphens(categoryName)}/${replaceSpacesWithHyphens(subcategoryName)}/${
                                        replaceSpacesWithHyphens(product?.descriptions &&
                                            product?.descriptions[0] &&
                                            product?.descriptions[0]?.value)
                                        }`:`/product/${product?.slug}`}
                                    >
                                        <ProductImages productimages={product?.productimages} />
                                    </Link>

                                    <center className="cart-footer">
                                        <Link
                                            // to={`/part-number-page/${
                                            to={product?.descriptions[0]?.value !== undefined ?`/${replaceSpacesWithHyphens(categoryName)}/${replaceSpacesWithHyphens(subcategoryName)}/${
                                                replaceSpacesWithHyphens(product?.descriptions &&
                                                product?.descriptions[0] &&
                                                product?.descriptions[0]?.value)
                                            }`:`/product/${product?.slug}`}
                                            className="card-price text-decoration-none"
                                        >
                                            <h6 className="m-0 current-price">
                                                ₹ {product?.saleprice}
                                            </h6>
                                            <p className="m-0 old-price">₹ {product?.oldprice}</p>
                                        </Link>

                                        <AddCartButton
                                            type="product_button"
                                            className="card-add-cart-button"
                                            data={product}
                                        />
                                    </center>

                                    <div className="card-content text-danger">
                                        <WishlistButton type="icon" data={product} />
                                        <div className="add-to-card">
                                            <ProductOveriew product={product} />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <Lottie
                            style={{ width: "100vw", height: "50vh" }}
                            animationData={NoProductFound}
                        />
                    )}
                </>
            )}
        </div>
    );
}

export default ViewSubProduct;
