// import React, { useEffect, useState } from "react";
// import { BiSearchAlt2 } from "react-icons/bi";
// import "../../components/Search/SearchBar.scss";
// import { useNavigate } from "react-router-dom";
// import { searchApi } from "../services/searchApi";

// function SearchBar() {
//   const [query, setQuery] = useState("");
//   const [results, setResults] = useState([]);
//   const [showDropdown, setShowDropdown] = useState(false);
//   const navicate = useNavigate();

//   useEffect(() => {
//     const fetchData = async () => {
//       if (query.trim() !== "") {
//         try {
//           const response = await searchApi({ query });
//           setResults(response);
//           setShowDropdown(response.length > 0);
//         } catch (error) {
//           console.log("error", error);
//         }
//       } else {
//         setResults([]);
//         setShowDropdown(false);
//       }
//     };

//     fetchData();
//   }, [query]);

//   const handleInputChange = (e) => {
//     const inputValue = e.target.value;
//     setQuery(inputValue);
//     setShowDropdown(!!inputValue);
//   };

//   const handleFocus = () => {
//     // setShowDropdown(true);
//   };

//   const handleBlur = () => {
//     setTimeout(() => setShowDropdown(false), 200);
//   };

//   const onSearchProductDetial = (data) => {
//     setQuery("");
//     setShowDropdown(false);
//     navicate(`/product/${data._id}`);
//   };

//   const onSearch = (e) => {
//     e.preventDefault();

//     if (query) {
//       setQuery("");
//       setShowDropdown(false);
//       navicate(`/search/${query}`);
//     }
//   };

//   return (
//     <form onSubmit={onSearch} className="d-flex nav-search">
//       <input
//         type="text"
//         className="nav-input-search dropdown-toggle position-relative"
//         placeholder="Enter Product, Part Number (English Only)"
//         onChange={handleInputChange}
//         // onFocus={handleFocus}
//         onBlur={handleBlur}
//         data-bs-toggle="dropdown"
//         aria-expanded="false"
//         value={query}
//       />

//       {showDropdown === true && (
//         <ul className="search-options">
//           {results.length > 0 ? (
//             <>
//               {results.map((result, i) => (
//                 <li key={i} onClick={() => onSearchProductDetial(result)}>
//                   {result?.partNumber}
//                 </li>
//               ))}
//             </>
//           ) : (
//             <div className="text-center text-danger my-4">No Data Found</div>
//           )}
//         </ul>
//       )}

//       <button className="nav-input-button">
//         <BiSearchAlt2 className="search-icon" />
//       </button>
//     </form>
//   );
// }

// export default SearchBar;




//----------------------------------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------------------------------------------
                                                  //07/25/2024
//----------------------------------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------------------------------------------





// import React, { useEffect, useState } from "react";
// import { BiSearchAlt2 } from "react-icons/bi";
// import "../../components/Search/SearchBar.scss";
// import { useNavigate } from "react-router-dom";
// import { searchApi } from "../services/searchApi";

// function SearchBar() {
//   const [query, setQuery] = useState("");
//   const [results, setResults] = useState([]);
//   const [showDropdown, setShowDropdown] = useState(false);
//   const [loading, setLoading] = useState(false); // Loading state
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchData = async () => {
//       if (query.trim() !== "") {
//         setLoading(true); // Set loading state to true
//         try {
//           const response = await searchApi({ query });
//           setResults(response);
//           setShowDropdown(response.length > 0);
//         } catch (error) {
//           console.log("error", error);
//         }
//         setLoading(false); // Set loading state to false
//       } else {
//         setResults([]);
//         setShowDropdown(false);
//       }
//     };

//     const debounceFetch = setTimeout(fetchData, 300); // Debounce API call

//     return () => clearTimeout(debounceFetch); // Cleanup timeout
//   }, [query]);

//   const handleInputChange = (e) => {
//     const inputValue = e.target.value;
//     setQuery(inputValue);
//     setShowDropdown(!!inputValue);
//   };

//   const handleBlur = () => {
//     setTimeout(() => setShowDropdown(false), 200);
//   };

//   const onSearchProductDetail = (data) => {
//     setQuery("");
//     setShowDropdown(false);
//     // navigate(`/product/${data._id}`);
//     // navigate(`/product/${data?.partNumber}`);
//     // navigate(data?.descriptions[0]?.value !== undefined ?`/product/${data?.slug}`:`/product/${data?.slug}`);
//     navigate(data?.descriptions[0]?.value !== undefined ?`/product/${data?.slug}`:`/product/${data?.slug}`);
//   };

//   const onSearch = (e) => {
//     e.preventDefault();

//     if (query) {
//       setQuery("");
//       setShowDropdown(false);
//       navigate(`/search/${query}`);
//     }
//   };

//   return (
//     <form onSubmit={onSearch} className="d-flex nav-search">
//       <input
//         type="text"
//         className="nav-input-search dropdown-toggle position-relative"
//         placeholder="Enter Product, Part Number (English Only)"
//         onChange={handleInputChange}
//         onBlur={handleBlur}
//         data-bs-toggle="dropdown"
//         aria-expanded="false"
//         value={query}
//       />

//       {showDropdown && (
//         <ul className="search-options">
//           {loading ? (
//             <div className="text-center my-4">Loading...</div>
//           ) : results.length > 0 ? (
//             results.map((result, i) => (
//               <li key={i} onClick={() => onSearchProductDetail(result)}>
//                 {result?.partNumber}
//               </li>
//             ))
//           ) : (
//             <div className="text-center text-danger my-4">No Data Found</div>
//           )}
//         </ul>
//       )}

//       <button className="nav-input-button" disabled={results.length > 0 ? false : true}>
//         <BiSearchAlt2 className="search-icon" />
//       </button>
//     </form>
//   );
// }

// export default SearchBar;
import React, { useEffect, useState } from "react";
import { BiSearchAlt2 } from "react-icons/bi";
import "../../components/Search/SearchBar.scss";
import { useNavigate } from "react-router-dom";
import { searchApi } from "../services/searchApi";

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
};

function SearchBar() {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (query.trim() === "") {
        setResults([]);
        setShowDropdown(false);
        return;
      }

      setLoading(true);
      setError(null);
      try {
        const response = await searchApi({ query });
        setResults(response);
        setShowDropdown(response.length > 0);
      } catch (error) {
        console.error("Error fetching search results:", error);
        setError("Failed to load results. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    const debouncedFetchData = debounce(fetchData, 300);
    debouncedFetchData();

    return () => clearTimeout(debouncedFetchData);
  }, [query]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
    setShowDropdown(e.target.value !== "");
  };

  const handleBlur = () => {
    setTimeout(() => setShowDropdown(false), 200);
  };

  const onSearchProductDetail = (data) => {
    setQuery("");
    setShowDropdown(false);
    navigate(`/product/${data?.slug}`);
  };

  const onSearch = (e) => {
    e.preventDefault();
    if (query) {
      navigate(`/search/${query}`);
      setQuery(""); 
      setShowDropdown(false);
    }
  };

  const removeHtmlTags = (text) => {
    return text.replace(/<\/?[^>]+(>|$)/g, ""); // Regex to remove HTML tags
  };

  return (
    <form onSubmit={onSearch} className="d-flex nav-search">
      <input
        type="text"
        className="nav-input-search dropdown-toggle position-relative"
        placeholder="Enter Product, Part Number (English Only)"
        onChange={handleInputChange}
        onBlur={handleBlur}
        value={query}
        aria-label="Search products"
      />

      {showDropdown && (
        <div className="search-options">
          {loading ? (
            <div className="text-center my-4">Loading...</div>
          ) : error ? (
            <div className="text-center text-danger my-4">{error}</div>
          ) : results.length > 0 ? (
            <>
                <h6 className="m-2 fw-bold">Description</h6>
              <ul>
                {results.map((result, i) => (
                  <li key={i} onClick={() => onSearchProductDetail(result)}>
                    {removeHtmlTags(result.writenotes)}
                  </li>
                ))}
              </ul>
                <h5 className="m-2 fw-bold">Part Numbers</h5>
              <ul>
                {results.map((result, i) => (
                  <li key={i} onClick={() => onSearchProductDetail(result)}>
                    {removeHtmlTags(result.partNumber)}
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <div className="text-center text-danger my-4">No Data Found</div>
          )}
        </div>
      )}

      <button className="nav-input-button" disabled={results.length === 0}>
        <BiSearchAlt2 className="search-icon" />
      </button>
    </form>
  );
}

export default SearchBar;
